import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "./familyMembers.module.css";
import { getTitle } from "../../../../utils/TextUtil";

export const FamilyMembers = React.forwardRef(({ data, onChange }, ref) => {
  const [memberDetails, setMemberDetails] = useState(data);
  const [currentMember, setCurrentMember] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleMemberChange = (key, value) => {
    setCurrentMember((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    setMemberDetails((prev) => {
      let updatedList = [...prev];
      if (modalType === "create") updatedList.push(currentMember);
      else if (modalType === "update")
        updatedList[currentIndex] = currentMember;
      else if (modalType === "delete") updatedList.splice(currentIndex, 1);
      onChange(updatedList);
      return updatedList;
    });
    handleClose();
  };

  const handleOpen = (type, index = null) => {
    setModalType(type);
    setCurrentIndex(index);
    setCurrentMember(
      index !== null
        ? memberDetails[index]
        : {
            name: "",
            relation: "",
            is_student: false,
            is_dependent: false,
            occupation: "",
            age: null,
          }
    );
  };

  const handleClose = () => {
    setModalType(null);
    setCurrentIndex(null);
    setCurrentMember(null);
  };

  return (
    <div className={styles.familyMembersContainer}>
      {memberDetails.map((member, index) => (
        <div key={index} className={styles.member}>
          <div>{member.name}</div>
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => handleOpen("update", index)}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => handleOpen("delete", index)}
          />
        </div>
      ))}
      <div className={styles.addFieldBtn} onClick={() => handleOpen("create")}>
        + Add Member
      </div>

      <Modal show={!!modalType} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "update"
              ? "Update Member"
              : modalType === "create"
              ? "Add Member"
              : "Delete Member"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "delete" ? (
            <>Are you sure you want to delete {currentMember?.name}?</>
          ) : (
            <>
              {["name", "age", "relation", "occupation"].map((field) => (
                <React.Fragment key={field}>
                  <Form.Control
                    value={currentMember?.[field] || ""}
                    type="text"
                    placeholder={`Enter ${field}`}
                    onChange={(e) => handleMemberChange(field, e.target.value)}
                  />
                  <br />
                </React.Fragment>
              ))}
              <div style={{ display: "flex", columnGap: "12px" }}>
                {["is_dependent", "is_student"].map((field) => (
                  <Form.Check
                    key={field}
                    checked={currentMember?.[field] || false}
                    type="checkbox"
                    label={getTitle(field)}
                    onChange={(e) =>
                      handleMemberChange(field, e.target.checked)
                    }
                  />
                ))}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {modalType === "update"
              ? "Update"
              : modalType === "create"
              ? "Add"
              : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});
