import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { DataProvider } from './context/DataContext';
import Login from './pages/auth/Login';
import VerifyOTP from './pages/auth/VerifyOTP';
import Dashboard from './pages/dashboard/Dashboard';
import Organisation from './pages/organisation/Organisation';
import { authenticateAxios } from "./utils/HttpUtil";
import './App.css';
import Product from "./pages/product/Product";
import Users from "./pages/users/Users";
import FormList from "./pages/forms/FormList";
import Branch from "./pages/branches/Branch";
import TestUsers from "./pages/test_users/TestUsers";
import Application from "./pages/applications/Application";
import Cam from "./pages/cams/Cam";
import { Reports } from './components/Reports';

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    if (user) {
        authenticateAxios(user.access_token, user.org_id);
        return children
    } else {
        return <Navigate to="/login" />
    }
};

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <DataProvider>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/verify-otp" element={<VerifyOTP />} />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute>
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/organisations"
                            element={
                                <ProtectedRoute>
                                    <Organisation data={{component: "organisation", cAction: "default"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/organisations/view/:org_id"
                            element={
                                <ProtectedRoute>
                                    <Organisation data={{component: "organisation", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/organisations/edit"
                            element={
                                <ProtectedRoute>
                                    <Organisation data={{component: "organisation", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/organisations/list"
                            element={
                                <ProtectedRoute>
                                    <Organisation data={{component: "organisation", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/products"
                            element={
                                <ProtectedRoute>
                                    <Product data={{component: "product", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/products/list"
                            element={
                                <ProtectedRoute>
                                    <Product data={{component: "product", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/products/view/:product_id"
                            element={
                                <ProtectedRoute>
                                    <Product data={{component: "product", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/products/edit/:product_id?"
                            element={
                                <ProtectedRoute>
                                    <Product data={{component: "product", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/branches"
                            element={
                                <ProtectedRoute>
                                    <Branch data={{component: "branch", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/branches/list"
                            element={
                                <ProtectedRoute>
                                    <Branch data={{component: "branch", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/branches/view/:branch_id"
                            element={
                                <ProtectedRoute>
                                    <Branch data={{component: "branch", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/branches/edit/:branch_id?"
                            element={
                                <ProtectedRoute>
                                    <Branch data={{component: "branch", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/forms/list"
                            element={
                                <ProtectedRoute>
                                    <FormList data={{component: "form", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute>
                                    <Users data={{component: "user", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users/list"
                            element={
                                <ProtectedRoute>
                                    <Users data={{component: "user", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users/view/:user_id"
                            element={
                                <ProtectedRoute>
                                    <Users data={{component: "user", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users/edit/:user_id?"
                            element={
                                <ProtectedRoute>
                                    <Users data={{component: "user", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users/bulk-user-create"
                            element={
                                <ProtectedRoute>
                                    <Users data={{component: "user", cAction: "bulk-user-create"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/test-users/list"
                            element={
                                <ProtectedRoute>
                                    <TestUsers data={{component: "test-user", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/test-users/edit/"
                            element={
                                <ProtectedRoute>
                                    <TestUsers data={{component: "test-user", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/applications"
                            element={
                                <ProtectedRoute>
                                    <Application data={{component: "application", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/applications/list/"
                            element={
                                <ProtectedRoute>
                                    <Application data={{component: "application", cAction: "list"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/applications/view/:application_id"
                            element={
                                <ProtectedRoute>
                                    <Application data={{component: "application", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/cams/view/:application_id"
                            element={
                                <ProtectedRoute>
                                    <Cam data={{component: "cam", cAction: "view"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/cams/edit/:application_id"
                            element={
                                <ProtectedRoute>
                                    <Cam data={{component: "cam", cAction: "edit"}}/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/cams/ugro/:applicationId/:templateId" element={<Reports />} />

                        <Route path="/" element={<Navigate to="/login" />} />
                    </Routes>
                </DataProvider>
            </AuthProvider>
        </Router>
    );
};

export default App;
