import { getTitle } from "../../utils/TextUtil";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "@mui/material";

function StaticDataComponent({ dataKey, static_data, isAccordion }) {
  return (
    <>
      <AccordionWrapper dataKey={dataKey} isAccordion={isAccordion}>
        <Row>
          <Col xs={12}>
            <span className="sub-heading">Borrower Details</span>
          </Col>
          <Col xs={12}>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>Borrower Photo</th>
                  <td>{static_data.borrower_details.borrower_photo}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{static_data.borrower_details.name}</td>
                </tr>
                <tr>
                  <th>Mobile</th>
                  <td>{static_data.borrower_details.mobile}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{static_data.borrower_details.email}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{static_data.borrower_details.dob}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={12}>
            <span className="sub-heading">Loan Details</span>
          </Col>
          <Col xs={12}>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>Constitution</th>
                  <td>{static_data.loan_details.constitution}</td>
                </tr>
                <tr>
                  <th>Quantum</th>
                  <td>{static_data.loan_details.quantum}</td>
                </tr>
                <tr>
                  <th>Business Model</th>
                  <td>{static_data.loan_details.business_model}</td>
                </tr>
                <tr>
                  <th>Industry</th>
                  <td>{static_data.loan_details.industry}</td>
                </tr>
                <tr>
                  <th>Purpose</th>
                  <td>{static_data.loan_details.purpose}</td>
                </tr>
                <tr>
                  <th>Tenure</th>
                  <td>{static_data.loan_details.tenure}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={12}>
            <span className="sub-heading">Co-Borrower Details</span>
          </Col>
          <Col xs={12}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Relation</th>
                  <th>Gender</th>
                </tr>
              </thead>
              <tbody>
                {static_data.co_borrower_details.co_borrower &&
                  static_data.co_borrower_details.co_borrower.map(
                    (cob, idx) => {
                      return (
                        <tr key={`cob_${idx}`}>
                          <td>{cob.photo}</td>
                          <td>{cob.name}</td>
                          <td>{cob.dob}</td>
                          <td>{cob.relation}</td>
                          <td>{cob.gender}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </Col>
          <Col xs={12}>
            <span className="sub-heading">Income Eligibility</span>
          </Col>
          {static_data.income_eligibility && (
            <Col xs={12}>
              <Table striped className="table table-striped">
                <thead className="table-striped table">
                  <tr colspan="2">
                    <th>Income Details</th>
                  </tr>
                  <tr>
                    <td>Key</td>
                    <td>Value</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Total Net Income</th>
                    <td>
                      {
                        static_data.income_eligibility.income_details
                          .total_net_income
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>DSCR</th>
                    <td>
                      {static_data.income_eligibility.income_details.dscr}
                    </td>
                  </tr>
                  <tr>
                    <th>FOIR</th>
                    <td>
                      {static_data.income_eligibility.income_details.foir}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table striped className="table table-striped">
                <tr>
                  <th>Family Expense</th>
                  <td>{static_data.income_eligibility.family_expenses}</td>
                </tr>
                <tr>
                  <th>Obligations</th>
                  <td>{static_data.income_eligibility.obligations}</td>
                </tr>
              </Table>
              <Table striped className="table table-striped">
                <thead>
                  <tr colspan="2">
                    <th>Source</th>
                  </tr>
                </thead>
                <tbody>
                  {static_data.income_eligibility.income_sources.map((is, ix) =>
                    Object.keys(is).map((key) =>
                      key === "sources" ? (
                        <tr key={`is_${key}_${ix}`}>
                          <th>{key}</th>
                          <td className="border-1">
                            {is[key].map((s, i) => (
                              <table className="table table-striped">
                                {Object.keys(s).map((k) =>
                                  k !== "basis" ? (
                                    <tr
                                      className="border-1"
                                      key={`iss_${k}_${ix}_${i}`}
                                    >
                                      <th>{k}</th>
                                      <td>{s[k]}</td>
                                    </tr>
                                  ) : (
                                    ""
                                  )
                                )}
                              </table>
                            ))}
                          </td>
                        </tr>
                      ) : (
                        <tr key={`is_${key}_${ix}`}>
                          <th>{key}</th>
                          <td>{is[key]}</td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </AccordionWrapper>
    </>
  );
}

export const AccordionWrapper = ({ children, dataKey, isAccordion }) => {
  return (
    <>
      {isAccordion ? (
        <Accordion.Item eventKey={dataKey} key={dataKey}>
          <Accordion.Header>{dataKey}</Accordion.Header>
          <Accordion.Body>{children}</Accordion.Body>
        </Accordion.Item>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default StaticDataComponent;
