// src/pages/branches/BranchView.js

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Image, Nav, NavDropdown} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useData} from "../../context/DataContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {jsonReq} from "../../utils/HttpUtil";
import Spinner from "react-bootstrap/Spinner";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import holder from '../../images/holder.png'
import {getTitle} from '../../utils/TextUtil'
import AcAudioPlayer from '../../components/AcAudioPlayer';

function ApplicationView() {
    const location = useLocation();
    const { orgRole } = useData();
    const navigate = useNavigate();
    const {application_id} = useParams()

    const [loading, setLoading] = useState(true);
    const [application, setApplication] = useState(null);
    const [error, setError] = useState(null);
    const [enhancedAudios, setEnhancedAudios] = useState([]);
    const [loadingEnhancedAudios, setLoadingEnhancedAudios] = useState(false);
    const [enhancedAudiosError, setEnhancedAudiosError] = useState(null);

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const res = await jsonReq().get("/dashboard/applications/get?application_id="+application_id);
                setApplication(res.data.data.application);
            }catch (e) {
                console.log("Error fetching organisation list: ", e);
                setError(e);
            }finally {
                setLoading(false);
            }
        }
        fetchApplication()
    }, [])

    const handleApplicationList = (ev) => {
        ev.preventDefault();
        navigate('/applications/list');
    };

    if (loading) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    if (error) {
        return (<p>Error: {error.message}</p>);
    }

    const renderBorrowerDetails = (borrower_details) => {
        return (
            <Row>
                <Col xs={4}>
                    <Image src={borrower_details.borrower_photo?.url} thumbnail/>
                </Col>
                <Col xs={8}>
                    <Table striped bordered hover>
                        <tbody>
                        <tr>
                            <td><strong>Name</strong></td>
                            <td>{borrower_details.name?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>DOB</strong></td>
                            <td>{borrower_details.dob?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Email</strong></td>
                            <td>{borrower_details.email?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Phone Number</strong></td>
                            <td>{borrower_details.mobile?.value}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const renderLoanDetails = (loan_details) => {
        return (
            <Row>
                <Col xs={12}>
                    <Table striped bordered hover>
                        <tbody>
                        <tr>
                            <td><strong>Constitution</strong></td>
                            <td>{loan_details.constitution?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Industry</strong></td>
                            <td>{loan_details.industry?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Loan Quantum</strong></td>
                            <td>{loan_details.quantum?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Business Model</strong></td>
                            <td>{loan_details.business_model?.value}</td>
                        </tr>
                        <tr>
                            <td><strong>Tenure</strong></td>
                            <td>{loan_details.tenure?.value} Years</td>
                        </tr>
                        <tr>
                            <td><strong>Loan Purpose</strong></td>
                            <td>{loan_details.purpose?.value}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const renderImages = (image_data) => {
        return (
            <Row>
                {Object.keys(image_data).map(k => {
                    return (
                        <Col xs={4} key={k}>
                            <span>{getTitle(k)}</span>
                            <Image key={`img-${k}`} src={image_data[k].url ? image_data[k].url : holder} thumbnail/>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const renderCoBorrowers = (co_borrowers) => {
        return co_borrowers.co_borrower?.value.map((cob) => {
            return (
                <Row className="cob-row">
                    <Col xs={4}>
                        <Image src={cob.value.photo?.url ? cob.value.photo?.url : holder} thumbnail/>
                    </Col>
                    <Col xs={8}>
                        <Table striped bordered hover>
                            <tbody>
                            <tr>
                                <td><strong>Name</strong></td>
                                <td>{cob.value.name?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>DOB</strong></td>
                                <td>{cob.value.dob?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>Gender</strong></td>
                                <td>{cob.value.gender?.value}</td>
                            </tr>
                            <tr>
                                <td><strong>Relation</strong></td>
                                <td>{cob.value.relation?.value}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )
        })
    }

    const renderEnhancedAudio = (business_pd) => {
        const fetchEnhancedAudios = async () => {
			setLoadingEnhancedAudios(true);
            setEnhancedAudios([]);
			setEnhancedAudiosError(null);
			try {
				const res = await jsonReq().get(
					`/dashboard/applications/get-enhanced-audios?application_id=${application_id}`
				);
                console.log("RESPONSE:",res);
				if (res.data.status === 1) setEnhancedAudios(res.data.data.enhanced_audios);
				else throw new Error(res.data.msg || 'Failed to fetch enhanced audios');
			} catch (e) {
				console.error('Error fetching enhanced audios:', e);
				setEnhancedAudiosError(e.data.msg || 'Failed to fetch enhanced audios');
				setEnhancedAudios([]);
			} finally {
				setLoadingEnhancedAudios(false);
			}
		};

        return (
			<Row>
				<Col xs={12}>
					<button className="btn btn-primary" onClick={fetchEnhancedAudios} disabled={loadingEnhancedAudios}>
						{loadingEnhancedAudios ? 'Loading...' : 'Get Enhanced Audio(s)'}
					</button>
				</Col>
				{enhancedAudiosError && (
					<Col xs={12} className="mt-3">
						<div className="alert alert-danger" role="alert">
							{enhancedAudiosError}
						</div>
					</Col>
				)}
				{enhancedAudios.map((audioUrl, index) => (
					<Col xs={12} className="mt-3" key={index}>
						<AcAudioPlayer
							audioUrl={audioUrl}
							badgeText="Enhanced"
							badgeTextColor="#fff"
							showDownload={true}
							showBadge={true}
							downloadFileName={`enhanced-audio-${index + 1}.mp3`}
						/>
					</Col>
				))}
			</Row>
		);
    };

    const renderBusinessPD = (business_pd) => {
        return (
			<>
			<Row>
            {business_pd.hasOwnProperty("business_pd") ? (
                <>
                    {business_pd.business_pd.value.business_information.value?.map((file)=>(
                        <Col xs={12} className="mt-3">
                        <AcAudioPlayer
                        audioUrl={file?.url}
                        primaryColor="#286bc4"
                        backgroundColor="#ffffff"
                        badgeColor="#286bc4"
                        badgeText="Original"
                        badgeTextColor="#fff"
                        showDownload={true}
                        showBadge={true}
                        downloadFileName={`original-audio.mp3`}
                        />
                    </Col>
                     ))}
              
                </>
          ) :  <Col xs={12} className="mt-3">
						{business_pd.business_information?.type === 'file' ? (
                            <Col xs={12} className="mt-3">
                                <AcAudioPlayer
                                    audioUrl={business_pd.business_information?.url}
                                    primaryColor="#286bc4"
                                    backgroundColor="#ffffff"
                                    badgeColor="#286bc4"
                                    badgeText="Original"
                                    badgeTextColor="#fff"
                                    showDownload={true}
                                    showBadge={true}
                                    downloadFileName={`original-audio.mp3`}
                                />
                            </Col>
						) : (
							business_pd.business_information?.value.map((aud, index) => (
                                <Col xs={12} className="mt-3" key={index}>
                                    <AcAudioPlayer
                                        audioUrl={aud.url}
                                        primaryColor="#286bc4"
                                        backgroundColor="#ffffff"
                                        badgeColor="#286bc4"
                                        badgeText={`Original`}
                                        badgeTextColor="#fff"
                                        showDownload={true}
                                        showBadge={true}
                                        downloadFileName={`original-audio-${index + 1}.mp3`}
                                    />
                                </Col>
							))
						)}
					</Col>}
				</Row>
				<Row className="mt-3">
					<Col xs={12}>
						<hr className="divider" />
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12}>{renderEnhancedAudio()}</Col>
				</Row>
			</>
		);
    };

    const renderDefault = () => {
        return (<Row>
            <Col xs={12}>
                <p>To Implement</p>
            </Col>
        </Row>)
    }

    const renderSection = (section) => {
        switch (section.name) {
            case 'borrower_details': return renderBorrowerDetails(section.data);
            case 'loan_details': return renderLoanDetails(section.data);
            case 'business_photos': return renderImages(section.data);
            case 'family_photos': return renderImages(section.data);
            case 'collateral_photos': return renderImages(section.data);
            case 'co_borrower_details': return renderCoBorrowers(section.data);
            case 'business_pd': return renderBusinessPD(section.data);
            case 'crif_details': return <CrifDetails data={section.data} />
            default: return renderDefault()
        }
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="page-head">
                        <span
                            className="lead">{application && application.organisation.name} ({application && application.organisation._id}) (Able ID - {application_id})</span>
                        <Nav className="ml-auto justify-content-end align-items-center">
                            <Nav.Link href="/applications/list" onClick={handleApplicationList}><FontAwesomeIcon
                                icon={faList}/>&nbsp;&nbsp;List</Nav.Link>
                            {application && ['verification_pending', 'cam_review','processing','visit_closed'].includes(application.status) && (
                                <Nav.Link className='pointer'   onClick={()=>navigate(`/cams/edit/${application_id}`)}><FontAwesomeIcon icon={faChartSimple}/>Reports</Nav.Link>
                            )}
                        </Nav>
                    </div>
                </Col>
                <Col xs={12}>
                    {
                        application && (
                            <Tabs defaultActiveKey="borrower_details" id={application._id} className="mb-3">
                                {application.sections.map(section => (
                                    <Tab key={section.name} title={getTitle(section.name)} eventKey={section.name}>{renderSection(section)}</Tab>
                                ))}
                            </Tabs>
                        )
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default ApplicationView;


const renderTableRows = (data) => {
    return Object.entries(data).map(([key, value]) => {
      if (!value?.value) return null; // Skip empty values
  
      return (
        <tr key={key}>
          <td className="fw-bold">{getTitle(key)}</td>
          <td>
            {Array.isArray(value.value) ? (
              <>
                {value.value.map((item, index) => (
                  <Table striped bordered hover>
                    <tbody>
                      <React.Fragment key={index}>
                        {renderTableRows(item.value)}
                      </React.Fragment>
                    </tbody>
                  </Table>
                ))}
              </>
            ) : (
              value.value
            )}
          </td>
        </tr>
      );
    });
  };
  
const CrifDetails = ({ data }) => {
    return (
      <Table striped bordered hover>
        <tbody>{renderTableRows(data)}</tbody>
      </Table>
    );
  };